exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-charity-js": () => import("./../../../src/templates/charity.js" /* webpackChunkName: "component---src-templates-charity-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-default-js": () => import("./../../../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-honorary-raiders-js": () => import("./../../../src/templates/honorary-raiders.js" /* webpackChunkName: "component---src-templates-honorary-raiders-js" */),
  "component---src-templates-matches-events-post-js": () => import("./../../../src/templates/matches-events-post.js" /* webpackChunkName: "component---src-templates-matches-events-post-js" */),
  "component---src-templates-sponsorship-landing-js": () => import("./../../../src/templates/sponsorship-landing.js" /* webpackChunkName: "component---src-templates-sponsorship-landing-js" */),
  "component---src-templates-sponsorship-single-js": () => import("./../../../src/templates/sponsorship-single.js" /* webpackChunkName: "component---src-templates-sponsorship-single-js" */),
  "component---src-templates-statistics-js": () => import("./../../../src/templates/statistics.js" /* webpackChunkName: "component---src-templates-statistics-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

